<template>
  <div class="tfoms__spacer">
    {{ title }}
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.tfoms__spacer {
  padding: 20px 0;
  margin-top: 20px;

  font-size: 18px;

  font-weight: 500;
  line-height: 158%;
  letter-spacing: -0.18px;
  text-align: center;

  color: var(--v-blue-grey-lighten3);
  border-top: solid 1px var(--v-blue-grey-lighten2);
}
</style>
